import { getOrCreateDataObject } from 'o365.vue.ts';
import { app } from 'o365.modules.configs.ts';

export default getOrCreateDataObject({
    id: 'dsOHelpPages',
    viewName: 'sviw_O365_HelpPages',
    distinctRows: false,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    appendData: false,
    disableLayouts: true,
    selectFirstRowOnLoad: true,
    fields: [{
        name: 'App_ID',
        type: 'string'
    },
    {
        name: 'Name',
        type: 'string'
    },
    {
        name: 'URL',
        type: 'string'
    },
    {
        name: 'SortOrder',
        type: 'string',
        sortOrder: 1,
        sortDirection: 'asc'
    },
    {
        name: 'ShowInDialog',
        type: 'boolean'
    },
    {
        name: 'DisableFocus',
        type: 'boolean'
    },
    {
        name: 'NameLocalized',
        type: 'string'
    },
    {
        name: 'URLLocalized',
        type: 'string'
    },
    {
        name: 'HideSubmit',
        type: 'boolean'
    }],
    clientSideHandler: false,
    maxRecords: -1,
    dynamicLoading: false,
    whereClause: `[App_ID] = '${app.id}' OR [App_ID] IS NULL`
});
